export type TrashablePromise<T> = Promise<T> & { trash: () => void };

export enum ClientErrorCodes {
  DomainResourceLimitExceededError = 'DomainResourceLimitExceededError',
  DuplicatedFileName = 'DuplicatedFileName',
  InvalidFileExtension = 'InvalidFileExtension',
}

export enum LoadingState {
  idle = 'idle',
  loading = 'loading',
  succeed = 'succeed',
  failed = 'failed',
}

export interface ServerError {
  status: HttpStatusCode;
  detail: string;
  source?: {
    pointer: string;
  };
  title?: string;
  code?: string;
}

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
}

export class HttpErrorResponse extends Error {
  errors: ServerError[];
  response: Response;

  constructor(response: Response, errors: ServerError[] = [], ...args: any[]) {
    super(...args);
    this.response = response;
    this.errors = errors;
  }
}

export interface HighbondDriveError {
  errorCode: ClientErrorCodes;
  message: string;
  customErrorMessage?: string;
}

export class HighbondDriveErrorResponse extends Error {
  errors: (HighbondDriveError | ServerError)[];

  constructor({ errors }: { errors: (HighbondDriveError | ServerError)[] }) {
    super();
    this.errors = errors;
  }
}

export class HttpConflictErrorResponse extends HttpErrorResponse {}

export class ConflictError extends Error {
  constructor() {
    super('Conflict Error');
  }
}
