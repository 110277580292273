import React from 'react';
import ErrorBubbly from '@acl-services/wasabicons/lib/BubblyError';
import NotificationCard from '@paprika/notification-card';
import useI18n from '@paprika/l10n/lib/useI18n';
import GenericError from '../GenericError/GenericError';

import './ErrorNotificationCard.scss';

interface ErrorNotificationProps {
  errorPrompt?: string;
}

export default function ErrorNotificationCard({ errorPrompt }: ErrorNotificationProps): JSX.Element {
  const I18n = useI18n();
  const text = errorPrompt ?? I18n.t('error_notification.body', { support_url: I18n.t('links.support_url') });

  return (
    <NotificationCard className="error-notification">
      <NotificationCard.Image align="center">
        <ErrorBubbly />
      </NotificationCard.Image>
      <NotificationCard.Header level={2}>{I18n.t('error_notification.heading')}</NotificationCard.Header>
      <NotificationCard.Body>
        <GenericError errorPrompt={text} />
      </NotificationCard.Body>
    </NotificationCard>
  );
}
