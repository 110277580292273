import { AssetAttributes, AttributePermissions } from './Attributes';
import { CustomerObjectContainerType, CustomerObjectType, CustomerObjectTypeName } from './CustomerObject';
import { DataList } from './Navigation';
import { AssetTypePermissions, AssetPermissions } from './Permissions';

export enum ObjectType {
  asset = 'asset_types',
  record = 'record_types',
}

export interface PermittedParent {
  targetId: string;
  targetType: ObjectType;
}

export interface AssetRecordType extends CustomerObjectType {
  typeName: CustomerObjectContainerType.RECORD_TYPES;
  permittedParents: PermittedParent[];
  permissions?: AssetTypePermissions;
  isRoot: boolean;
}

export interface AssetRecord {
  id: string;
  name: string;
  typeName: CustomerObjectTypeName.RECORDS;
  recordAttributes: AssetAttributes;
  attributePermissions: AttributePermissions;
  workflowStatus: {
    id: string;
    name: string;
  };
  permissions?: AssetPermissions;
  [key: string]: any;
}

export type AssetRecordList = DataList<AssetRecord>;
