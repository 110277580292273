export interface AssetTypePermissions {
  createAsset: boolean;
}

export interface AssetPermissions {
  deleteAsset: boolean;
  readAssetSection0: boolean;
  updateAssetSection0: boolean;
  [section: string]: boolean;
}

export class ForbiddenError extends Error {
  constructor() {
    super('Forbidden');
  }
}
