import _ from 'lodash';
import JSONAPISerializer from 'json-api-serializer';
import { CustomerObjectTypeName } from 'types';

export default function register(serializer: JSONAPISerializer): void {
  serializer.register('records', {
    afterDeserialize: (data: Record<string, any>) => {
      data.recordAttributes = _.keyBy(data.recordAttributes, (attr) => attr.fieldName);
      data.attributePermissions = _.mapValues(data.recordAttributes, (attr) => !attr.canNotRead);
      const workflowStatus = data.recordAttributes['metadata.workflow_status'].value;
      delete data.recordAttributes['metadata.workflow_status'];
      data.workflowStatus = workflowStatus;

      data.typeName = CustomerObjectTypeName.RECORDS;

      return data;
    },
  });

  serializer.register('record_relationships', { id: 'fieldName' });
}
