import React from 'react';
import useI18n from '@paprika/l10n/lib/useI18n';
import SanitizedText from 'components/common/SanitizedText';

interface GenericErrorProps {
  errorPrompt?: string;
}

export default function GenericError({ errorPrompt }: GenericErrorProps): JSX.Element {
  const I18n = useI18n();
  const text = errorPrompt ?? I18n.t('error_notification.body', { support_url: I18n.t('links.support_url') });

  return <SanitizedText text={text} />;
}
