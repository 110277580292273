import JSONAPISerializer from 'json-api-serializer';
import _ from 'lodash';
import { CustomerObjectTypeName } from 'types';

export default function register(serializer: JSONAPISerializer): void {
  serializer.register('assets', {
    afterDeserialize: (data: Record<string, any>) => {
      data.assetAttributes = _.keyBy(data.assetAttributes, (attr) => attr.fieldName);
      data.attributePermissions = _.mapValues(data.assetAttributes, (attr) => !attr.canNotRead);
      const workflowStatus = data.assetAttributes['metadata.workflow_status'].value;
      delete data.assetAttributes['metadata.workflow_status'];
      data.workflowStatus = workflowStatus;

      data.typeName = CustomerObjectTypeName.ASSETS;

      return data;
    },
  });

  serializer.register('asset_relationships', { id: 'fieldName' });
}
